import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Dialog } from '@reach/dialog';
import UpArrowSVG from '../../components/UpArrowSVG';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import BathroomsImageGrid from '../../components/BathroomsImageGrid';
import '@reach/dialog/styles.css';
import CloseSVG from '../../components/CloseSVG';

const Bathrooms = () => {

    const [showDialog, setShowDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const open = imageData => {
        setSelectedImage(imageData);
        setShowDialog(true);
    };

    const close = () => setShowDialog(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Layout>
            <SEO
                title="Bathrooms"
                description="Take a look at our incredible bathroom designs. From beautiful tile to expertly built showers and baths, our bathrooms are always made to impress and perform."
            />
            <section className="main-bathrooms" aria-label="See Bathroom Photos">
                <div className="main-bathrooms__container">
                    <h2 className="main-bathrooms__title">
                        Bathrooms
                    </h2>
                    <BathroomsImageGrid open={open} />
                    <button onClick={scrollToTop} className="main-bathrooms__scroll">
                        <UpArrowSVG className="main-bathrooms__svg" />
                        <span className="visually-hidden">Scroll To Top</span>
                    </button>
                </div>
            </section>
            <Dialog isOpen={showDialog} onDismiss={close}>
                <button className="dialog-close" onClick={close}>
                    <CloseSVG className="dialog-svg" />
                </button>
                <GatsbyImage image={selectedImage} className="dialog-image" alt="" />
            </Dialog>
        </Layout>
    );
};

export default Bathrooms;
